<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Vouchers Redemptions</h4>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-2 mt-1">
                      <Datepicker placeholder="Start Date" v-model="startDate"  utc  autoApply  :format="customFormatter"></Datepicker>
                    </div>
                    <div class="col-md-2 mt-1">
                      <Datepicker placeholder="End Date" v-model="endDate"  utc  autoApply  :format="customFormatter"></Datepicker>
                    </div>
                    <div class="col-md-2 mt-1" v-if="report !='duevouchers'">
                      <select class="form-control" id=""   v-model="location">
                        <option value="">Select Store</option>
                        <option v-for="store in stores" :key="store.id" :value="store.id">{{store.branch_name}}</option>
                      </select>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn_reserveport_orange mt-1 mr-1" @click="searchPayments()">search</button>
                        <button class="btn btn_reserveport_orange mt-1" @click="getExportData()" ><i v-if="exportLoading" class="fa fa-spinner fa-spin"></i> &nbsp; <i v-if="!exportLoading"  class="fas fa-file-export"></i>   Export</button>
                      </div>
                    </div>
                  </div>
                </div>
                  
              </div>
              <hr>
            </div>
            <div class="col-md-12 col-sm-12">
              <div class="cardbox card">
                <div class="card-body  ">
                  <div class="table_section">
                    <table class="table  table-hover">
                      <thead>
                        <th>Date</th>
                        <th>Voucher Name</th>
                        <th>Code</th>
                        <th>Amount</th>
                        <th>Source</th>
                        <th>Location</th>
                        <th>Admin Generated</th>
                        <th>Reference</th>
                      </thead>
                      <tbody v-if="!loading">
                        <tr v-for="redemption in redemptions" :key="redemption.id">
                          <td>{{formatDate(redemption.created_at,1)}}</td>
                          <td>{{redemption.title}}</td>
                          <td>{{redemption.code}}</td>
                          <td>{{formatPrice(redemption.amount)}}</td>
                          <td>{{redemption.source}}</td>
                          <td>{{redemption.location}}</td>
                          <td>{{redemption.bulk_generated ? 'Yes' : 'No'}}</td>
                          <td>{{redemption.reference}}</td>
                        </tr>
                      </tbody>
                      <tfoot v-if="!loading">
                        <tr>
                          <td colspan="8">
                            <div id="pagination" v-if="totalPages > 1">
                              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                              <ul class="showItems">
                                <li>Show Items:
                                  <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                    <option :value="10">10</option>
                                    <option :value="20">20</option>
                                    <option :value="30">30</option>
                                    <option :value="40">40</option>
                                    <option :value="50">50</option>
                                  </select>
                                </li>

                              </ul>
                            </div>

                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div v-if="loading" class="loading-cover">
                    <div class="loader"> </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import moment from 'moment';
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  import Pagination from '@/pages/pagination/pagination.vue';
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  
  export default {
      components: {
          Pagination,
      },
      data() {
          return {
              redemptions: [],
              location : '',
              stores: [],
              loading: false,
              exportLoading: false,
              page: 1,
              totalPages: 0,
              totalRecords: 0,
              recordsPerPage: 10,// this.$store.state.voucherPaymentRecordPerPage,
              spinner: '',
              startDate:this.$store.state.rStartDate,
              endDate: this.$store.state.rEndDate,
          }
      },
      created: async function () {
          this.getRedemptions();
          this.getBranches();
      },
      methods: {
        searchPayments(){
            this.getRedemptions()
        },

        customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        async getExportData(){
            this.exportLoading = true;
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              let url = `${this.dashBaseUrl}/giftvoucher-redemptions/${this.merchantID}`;
              url = new URL(url);
              if(this.location !=''){
                url.searchParams.set('location',this.location)
              }
              if(this.startDate !==''){
                url.searchParams.set('start_date', moment(this.startDate).format('YYYY-MM-DD'));
                }
                if(this.endDate !== ''){
                    url.searchParams.set('end_date', moment(this.endDate).format('YYYY-MM-DD'));
                }
              try {
                // 
                const response = await this.axios.get(url, config)
                console.log("response",response.data)
                if(response.status == 200){
                    const redemptionactivities = response.data.data
                    const redemptionDetails = []
                    for(const redemption of Object.values(redemptionactivities)){
                        redemptionDetails.push({
                        'Date':this.formatDate(redemption.created_at),
                        'Name':redemption.title,
                        'Code':redemption.code,
                        'Amount':redemption.amount,
                        'Source':redemption.source,
                        'Location':redemption.location,
                        'Admin Generated':redemption.bulk_generated ? 'Yes' : 'No',
                        'Reference':redemption.reference
                        })
                    }
                    const fileName = Date.now() + '_redemptionactivities_report'
                    const exportData = redemptionDetails
                    this.exportExcel(exportData, fileName)
                    this.exportLoading = false
                }
              }catch(error){
                this.exportLoading = false
                if(error.response.status === 401){
                      this.$router.push({
                          name: 'login'
                      });
                }
              }
        },
        async getBranches() {
            const url = this.baseUrl + "/branch?merchant_id="+ this.merchantID;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const stores = response.data.data;
                        this.stores = stores;
                    }
                }
            }).catch((err) => {
                console.log('error', err);
            })
        },
          async getRedemptions(){
             this.loading = true;
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              let url = `${this.dashBaseUrl}/giftvoucher-redemptions/${this.merchantID}`;
              url = new URL(url);
              url.searchParams.set('page', this.page);
              url.searchParams.set('size', this.recordsPerPage);
              if(this.location !=''){
                url.searchParams.set('store',this.location)
              }
              if(this.startDate !==''){
                url.searchParams.set('start_date', moment(this.startDate).format('YYYY-MM-DD'));
                }
                if(this.endDate !== ''){
                    url.searchParams.set('end_date', moment(this.endDate).format('YYYY-MM-DD'));
                }
              try {
                // 
                const response = await this.axios.get(url, config)
                console.log("response",response.data.data.data)
                if(response.status == 200){
                  this.redemptions = response.data.data.data
                  this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage);
                  this.totalRecords = response.data.data.total; 
                  this.loading = false
                }
              }catch(error){
                this.loading = false
                if(error.response.status === 401){
                      this.$router.push({
                          name: 'login'
                      });
                }
              }
  
          },
          onPageChange(page) {
              this.page = page
              this.getRedemptions();
          },
          onChangeRecordsPerPage() {
            const payload = {
                  "type":"vouchers_payment",
                  "size":this.recordsPerPage
              }
              this.$store.commit("setPageLimit",payload)
              this.getRedemptions();
          },
  
      },
      mixins: [ReusableDataMixins, ReusableFunctionMixins]
  }
  </script>
  